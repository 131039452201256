import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography,
    useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import { FILTER_TYPE, FilterType } from '../../../constants/filterLists'
import { defaultSequence } from '../../../data/defaults/objects.types.defaults'
import { defaultEase } from '../../../data/styles/ease.styles'
import { sequence } from '../../../data/styles/tweens.styles'
import { createObject } from '../../../helpers/creators.helpers'
import { dateToString } from '../../../helpers/date.helpers'
import { getGraphicImageUrl } from '../../../helpers/graphic.helpers'
import { selectUndoable } from '../../../helpers/selector.helpers'
import { getFullNameString } from '../../../helpers/string.helpers'
import { useAnimationActions } from '../../../hooks/useAnimationActions'
import { useCurrentCompanyStats } from '../../../hooks/useCompanyStats'
import useFramerate from '../../../hooks/useFramerate'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
} from '../../../store/slices/activeAnimation.slice'
import {
    clearAllSelectedObjectsAction,
    setActiveObjectAction,
} from '../../../store/slices/activeObject.slice'
import { AssetT, createAssetFulfilled } from '../../../store/slices/assets.slice'
import { selectObjects } from '../../../store/slices/objects.slice'
import { AppStateT } from '../../../store/store'
import { Transition } from '../../common/Transition'

interface Props {
    open: boolean
    onClose: () => void
    parentId: string | null
    object?: AnySceneObjectT
    type: FilterType
}

const AssetsDialog = ({ open, onClose, parentId, object, type }: Props) => {
    const { readImageFileFromURL, replaceImageSourceFromUrl } = useObjectActions()
    const { createSequenceAnimations } = useAnimationActions()
    const dispatch = useAppDispatch()
    const objects: AnySceneObjectT[] = useAppSelector((state: AppStateT) =>
        selectObjects(selectUndoable(state).objects)
    )

    const { assets } = useCurrentCompanyStats()
    const theme = useTheme()

    const { setFramerateValue } = useFramerate()

    const handleClick = (asset: AssetT) => {
        if (asset.type === 'image') {
            if (object) {
                replaceImageSourceFromUrl(asset.url!, object)
            } else {
                readImageFileFromURL(asset.url!, parentId)
            }
        } else if (asset.type === 'sequence') {
            const item = createObject(objects, defaultSequence, parentId)

            if (!item) return

            const step = setFramerateValue(1)

            const animations =
                asset.urls?.map((url, index) => {
                    const [name, , extension] = (url.split('/').pop() ?? '').split('.')
                    const animation: AnimationI = {
                        id: uuidv4(),
                        target: null,
                        title: `${name}.${extension}`,
                        objectId: item.id,
                        duration: step,
                        delay: index * step,
                        repeat: 0,
                        tween: sequence(url),
                        relativeTo: null,
                        ease: defaultEase,
                        onInitCode: null,
                        onUpdateCode: null,
                    }
                    return animation
                }) ?? []

            const img = new Image()
            img.addEventListener('load', function () {
                createSequenceAnimations(animations, item, { width: img.width, height: img.height })
            })
            if (asset.urls) img.src = asset.urls[0]
            const animationIds: string[] = animations.map((animation) => animation.id)
            dispatch(clearSelectedAnimationsAction())
            dispatch(clearAllSelectedObjectsAction())
            dispatch(setActiveObjectAction({ id: item.id }))
            dispatch(addSelectedAnimationsAction(animationIds))
        }

        dispatch(createAssetFulfilled(asset))
        onClose()
    }

    const Icon = FILTER_TYPE.find((f) => f.name === type)?.icon

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            sx={{ zIndex: 1400 }}
            maxWidth={'xl'}
        >
            <Box m={3}>
                <DialogTitle>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ display: 'flex', alignItems: 'center', pb: 1 }}
                    >
                        {Icon && <Icon sx={{ mr: 1 }} fontSize="large" />}
                        {t('editor:assetsDialog.title')}
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 32,
                        top: 32,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <ImageList cols={6}>
                        {assets
                            .filter((asset) => asset.type && type?.includes(asset.type))
                            .map((asset) => (
                                <ImageListItem key={asset.slug} onClick={() => handleClick(asset)}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: theme.palette.grey[900],
                                            width: 160,
                                            height: 160,
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={getGraphicImageUrl(asset)}
                                            sx={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                opacity: 0.7,
                                            }}
                                        />
                                    </Box>
                                    <ImageListItemBar
                                        title={
                                            asset.name.length > 20
                                                ? `${asset.name.substring(0, 16)}...`
                                                : asset.name
                                        }
                                        subtitle={
                                            <span>
                                                {asset.updated && dateToString(asset.updated)}
                                                {' | '}
                                                {!asset.user?.firstName && !asset.user?.lastName
                                                    ? t('graphics:anonymous')
                                                    : getFullNameString(
                                                          asset.user?.firstName,
                                                          asset.user?.lastName
                                                      )}
                                            </span>
                                        }
                                        position="below"
                                    />
                                </ImageListItem>
                            ))}
                    </ImageList>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default AssetsDialog

import { ActionCreators } from 'redux-undo'
import { refreshAssets } from 'tweenly'
import { defaultTimeline } from '../data/defaults/timeline.types.defaults'
import { selectRefreshLabels } from '../helpers/selector.helpers'
import { updateMasterTimeline } from '../store/slices/masterTimeline.slice'
import {
    filterTimelineLabelsByTime,
    updTimelineAction,
    uplTimelineAction,
} from '../store/slices/timeline.slice'
import { useAppDispatch, useAppSelector } from './useRedux'

export const useTimelineActions = () => {
    const dispatch = useAppDispatch()

    const timeline: TimelineI = useAppSelector((state) => state.timeline.value)
    const masterTimeline: GSAPTimeline | null = useAppSelector(
        (state) => state.masterTimeline.value
    )
    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)

    const uploadTimeline = async (timeline: TimelineI) => {
        masterTimeline?.clear()
        await dispatch(uplTimelineAction({ timeline: timeline }))
        // commented to temporarily fix creation of gs-dev-tools timeline when app crashes
        // @ts-ignore
        await dispatch(updateMasterTimeline(timeToSeek))
        dispatch(ActionCreators.clearHistory())
    }

    const updateTimeline = (property: any, value: any) => {
        masterTimeline?.clear()
        dispatch(updTimelineAction({ property: property, value: value }))
        // @ts-ignore
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const addTimelineLabel = (label: TimelineLabelI) => {
        if (timeline.labels?.find((lbl: TimelineLabelI) => lbl.time === label.time) !== undefined) {
            return
        }
        const newlabels: TimelineLabelI[] = timeline.labels ? [...timeline.labels, label] : [label]
        updateTimeline('labels', newlabels)
    }

    const delTimelineLabel = (labeltitle: string) => {
        updateTimeline(
            'labels',
            timeline.labels.filter((label: TimelineLabelI) => label.title !== labeltitle)
        )
    }

    const updTimelineLabelCode = (labeltitle: string, code: CodeTriggerT) => {
        updateTimeline(
            'labels',
            timeline.labels.map((label: TimelineLabelI) => {
                return label.title === labeltitle ? { ...label, onLabelCode: code } : label
            })
        )
    }

    const upsertTimelineLabel = (newLabel: TimelineLabelI) => {
        if (timeline.labels.some((label) => label.title === newLabel.title)) {
            updateTimeline(
                'labels',
                timeline.labels.map((label: TimelineLabelI) => {
                    return label.title === newLabel.title ? newLabel : label
                })
            )
        } else {
            const newlabels: TimelineLabelI[] = [...timeline.labels, newLabel]
            updateTimeline('labels', newlabels)
        }
    }

    const refreshMasterTimeline = () => {
        refreshAssets()
        // @ts-ignore
        masterTimeline?.clear()
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const reinitTimeline = () => {
        // @ts-ignore
        masterTimeline?.clear()
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const effectiveTimelineDuration = masterTimeline ? masterTimeline.duration() : 0
    const masterTimelineDuration = Math.max(
        masterTimeline ? masterTimeline.duration() : 0,
        timeline.minduration ? timeline.minduration : defaultTimeline.minduration
    )

    const seekMasterTimeline = (position: string | number) => {
        if (masterTimeline) {
            masterTimeline.seek(position, false)
        }
    }

    const pauseMasterTimeline = (position: string | number) => {
        if (masterTimeline) {
            masterTimeline.pause(position, false)
        }
    }

    const labelsInTimeStamp = (from: number, to: number): TimelineLabelI[] => {
        return filterTimelineLabelsByTime(timeline, from, to).sort(
            (a: TimelineLabelI, b: TimelineLabelI) => {
                return a.time - b.time
            }
        )
    }

    const jumpLabelInArray = (labels: TimelineLabelI[]): boolean => {
        return labels.find((label) => label.type === 'jump') !== undefined
    }

    const pauseLabelInArray = (labels: TimelineLabelI[]): boolean => {
        return labels.find((label) => label.type === 'pause') !== undefined
    }

    const refreshLabels = useAppSelector(selectRefreshLabels)

    return {
        refreshLabels,
        uploadTimeline,
        updateTimeline,
        addTimelineLabel,
        delTimelineLabel,
        upsertTimelineLabel,
        updTimelineLabelCode,
        refreshMasterTimeline,
        reinitTimeline,
        masterTimeline,
        masterTimelineDuration,
        effectiveTimelineDuration,
        seekMasterTimeline,
        pauseMasterTimeline,
        labelsInTimeStamp,
        jumpLabelInArray,
        pauseLabelInArray,
    }
}

import { selectUndoable } from '../../../helpers/selector.helpers'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppSelector } from '../../../hooks/useRedux'
import { selectObjectById, selectObjectsByIds } from '../../../store/slices/objects.slice'
import { ScrollableContainer } from '../../../style/styles'
import TitleHeading from '../../common/TitleHeading'
import ImageTools from './ImageTools'
import ItemTools from './ItemTools'
import MultiselectItemsTools from './MultiselectItemsTools'
import SequenceTools from './SequenceTools'
import TextTools from './TextTools'

interface Props {
    objectId: string
}

const ObjectTools = ({ objectId }: Props) => {
    const object: AnySceneObjectT = useAppSelector((state) =>
        selectObjectById(selectUndoable(state).objects, objectId)
    )

    const { updateObject, updateObjectStyle, updateObjectAttribute } = useObjectActions()

    const setValue = (property: any, value: any) => {
        if (object) updateObject(object.id, property, value)
    }
    const setStyleValue = (property: string, value: number | string) =>
        updateObjectStyle(object, property, value)
    const setAttributeValue = (property: string, value: boolean) =>
        updateObjectAttribute(object, property, value)
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const objects = useAppSelector((state) => selectUndoable(state).objects)
    const getSelectedObjects = (): ObjectI[] => {
        return selectObjectsByIds(objects, selectedObjectIds)
    }
    const getSelectedAnimationsTitle = (): string => {
        let result: string = ''
        Object.values(getSelectedObjects()).forEach((object: ObjectI) => {
            if (result !== '') result += ', '
            result += object?.title
        })
        return result
    }
    return (
        <ScrollableContainer>
            <div>
                <TitleHeading
                    multiline={selectedObjectIds.length > 1}
                    object={selectedObjectIds.length < 2 ? object : undefined}
                    handleSetValue={selectedObjectIds.length < 2 ? setValue : undefined}
                    title={selectedObjectIds.length < 2 ? undefined : getSelectedAnimationsTitle()}
                />
                {selectedObjectIds.length > 1 ? (
                    <MultiselectItemsTools />
                ) : (
                    <>
                        {object && object.type === 'item' && (
                            <ItemTools
                                object={object}
                                handleSetStyleValue={setStyleValue}
                                handleSetObjectValue={setValue}
                                handleSetAttributeValue={setAttributeValue}
                            />
                        )}
                        {object && object.type === 'text' && (
                            <TextTools
                                object={object}
                                handleSetStyleValue={setStyleValue}
                                handleSetObjectValue={setValue}
                                handleSetAttributeValue={setAttributeValue}
                            />
                        )}
                        {selectedObjectIds.length < 2 && object && object.type === 'image' && (
                            <ImageTools
                                object={object}
                                handleSetStyleValue={setStyleValue}
                                handleSetObjectValue={setValue}
                                handleSetAttributeValue={setAttributeValue}
                            />
                        )}
                        {selectedObjectIds.length < 2 && object && object.type === 'sequence' && (
                            <SequenceTools
                                object={object}
                                handleSetStyleValue={setStyleValue}
                                handleSetObjectValue={setValue}
                                handleSetAttributeValue={setAttributeValue}
                            />
                        )}
                    </>
                )}
            </div>
        </ScrollableContainer>
    )
}

export default ObjectTools

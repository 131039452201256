import { Box, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { setTimeToSeek } from '../../../../store/slices/masterTimeline.slice'
import { filterTimelineLabelsByTime } from '../../../../store/slices/timeline.slice'
import { TIMELINE_ROW } from '../../../../style/sizing'
interface Props {
    width: number
    visibility: 'visible' | 'hidden'
}

const TimelinePlayhead = ({ width, visibility }: Props) => {
    const theme = useTheme()
    const effectiveDuration: number = useTimelineActions().effectiveTimelineDuration
    const masterTimeline = useTimelineActions().masterTimeline
    const masterTimelineTime = useAppSelector((state) => state.masterTimeline.time)
    const timeline: TimelineI = useAppSelector((state) => state.timeline.value)
    const dispatch = useAppDispatch()

    const { pauseMasterTimeline, seekMasterTimeline } = useTimelineActions()

    const [timelineTime, setTimelineTime] = useState(0)
    const [lastTimelineTime, setLastTimelineTime] = useState(0)

    const debounceTimelineTime = useDebounce(timelineTime ?? 0)

    const progressStyle = (width: number): { background: string } => {
        let background: string = 'rgba(0,0,0,0)'
        const playheadWidth: number = 8
        const backgroundColor: string = 'rgba(0,0,0,0)'
        const highlightColor: string = theme.palette.error.main
        const progress: number = masterTimeline ? masterTimeline.time() / effectiveDuration : 0
        const playheadPosition: number = progress * width

        background = `linear-gradient(to right,
            ${backgroundColor} 0px,
            ${backgroundColor} ${playheadPosition - playheadWidth / 2}px,
            ${highlightColor} ${playheadPosition - playheadWidth / 2}px,
            ${highlightColor} ${playheadPosition + playheadWidth / 2}px,
            ${backgroundColor} ${playheadPosition + playheadWidth / 2}px,
            ${backgroundColor} ${width}px)`

        return {
            background,
        }
    }

    //masterTimeline?.eventCallback must be in useEffect
    //only depend when materTimeline was changed
    useEffect(() => {
        masterTimeline?.eventCallback('onUpdate', () => {
            setLastTimelineTime(timelineTime)
            setTimelineTime(masterTimeline.time())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterTimeline])

    //set timelineTime in reducer
    if (
        debounceTimelineTime !== masterTimelineTime &&
        (masterTimeline?.paused() || masterTimeline?.time() === masterTimeline?.duration())
    ) {
        dispatch(setTimeToSeek(debounceTimelineTime))
    }

    if (masterTimeline) {
        if (timelineTime > lastTimelineTime) {
            const labels: TimelineLabelI[] = filterTimelineLabelsByTime(
                timeline,
                lastTimelineTime,
                timelineTime
            )
            if (labels?.length > 0) {
                labels.forEach((label) => {
                    if (label.type === 'pause') {
                        pauseMasterTimeline(label.time)
                    } else if (label.type === 'jump' && lastTimelineTime < timelineTime) {
                        const jumpToLabel = timeline.labels.find(
                            (lbl) => lbl.title === label.jumpto
                        )
                        if (jumpToLabel) {
                            seekMasterTimeline(jumpToLabel.time)
                        }
                    }
                })
            }
        }
    }

    if (!masterTimeline) return <></>
    return (
        <Box
            sx={{
                position: 'absolute',
                height: TIMELINE_ROW.height - 10 + 'px',
                width: width + 'px', //timelineRowWidth + 'px', //100%
                overflow: 'visible',
                background: progressStyle(width).background,
                zIndex: 60,
                visibility: { visibility },
                pointerEvents: 'none',
            }}
        />
    )
}

export default TimelinePlayhead

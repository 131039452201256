import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import { useAppSelector } from '../../../../hooks/useRedux'
import { selectObjectsByIds } from '../../../../store/slices/objects.slice'
import { selectAnimationById } from '../../../../store/slices/objects.slice/animation/selectAnimationById'
import { TIMELINE_ROW } from '../../../../style/sizing'
import EmptyPanel from '../../../common/EmptyPanel'
import TimelineRow from './TimelineRow'

interface Props {
    objects: AnySceneObjectT[]
    timelineRowWidth: number
    isHidingObject: boolean
}

const TimelineRows = ({ objects, timelineRowWidth, isHidingObject }: Props) => {
    const editor = useAppSelector((state) => state.editor)
    const rowsExpanded: boolean = editor.value.settings.timelineRowsExpanded
    const stateObjects = useAppSelector((state) => selectUndoable(state).objects)

    const timelineRowAreaWidth: number = TIMELINE_ROW.headingWidth
    const timelineAreaWidth = timelineRowWidth + timelineRowAreaWidth

    const compareAnimations = (a1: string, a2: string) => {
        const animation1 = selectAnimationById(Object.values(stateObjects.value), a1)
        const animation2 = selectAnimationById(Object.values(stateObjects.value), a2)

        if (animation1 && animation2) {
            if (animation1.tween.name < animation2.tween.name) {
                return -1
            }
            if (animation1.tween.name > animation2.tween.name) {
                return 1
            }
            return animation1.delay - animation2.delay
        }
        return 0
    }

    const sortAnimations = (animationIds: string[]): string[] => {
        const sortedAnimations = animationIds.slice().sort(compareAnimations)
        return sortedAnimations
    }

    if (!objects || objects.length === 0)
        return <EmptyPanel text1="Timeline" text2="Animate the objects" />
    else
        return (
            <Grid
                py={0}
                sx={{
                    width: timelineAreaWidth + 'px',
                    marginBottom: TIMELINE_ROW.paddingBottom,
                }}
            >
                {objects.map((object: AnySceneObjectT, index: number) => {
                    let animationIdsLength = object.animations
                        ? Object.keys(object.animations).length
                        : 0

                    const animationIds = object.animations ? Object.keys(object.animations) : []

                    if (!object || (animationIdsLength === 0 && object.childIds.length === 0))
                        return <React.Fragment key={index}></React.Fragment>
                    if (animationIdsLength === 0 && object.childIds.length > 0)
                        return (
                            <TimelineRows
                                objects={selectObjectsByIds(stateObjects, object.childIds)}
                                timelineRowWidth={timelineRowWidth}
                                isHidingObject={
                                    isHidingObject || (object.isHidden ? object.isHidden : false)
                                }
                            />
                        )
                    if (object.type !== 'sequence' && rowsExpanded && animationIdsLength > 0)
                        return (
                            <Box
                                key={index}
                                sx={{
                                    width: { timelineAreaWidth } + 'px',
                                    marginBottom: TIMELINE_ROW.paddingBottom + 'px',
                                }}
                            >
                                {sortAnimations(animationIds).map(
                                    (animationId: string, index: number) => (
                                        <TimelineRow
                                            key={index}
                                            object={object}
                                            animationId={animationId}
                                            isMainRow={index === 0}
                                            timelineRowWidth={timelineRowWidth}
                                            isHidingObject={
                                                isHidingObject ||
                                                (object.isHidden ? object.isHidden : false)
                                            }
                                        />
                                    )
                                )}
                                {object.childIds.length > 0 && (
                                    <TimelineRows
                                        objects={selectObjectsByIds(stateObjects, object.childIds)}
                                        timelineRowWidth={timelineRowWidth}
                                        isHidingObject={
                                            isHidingObject ||
                                            (object.isHidden ? object.isHidden : false)
                                        }
                                    />
                                )}
                            </Box>
                        )
                    else
                        return (
                            <Box
                                key={index}
                                sx={{
                                    width: { timelineAreaWidth } + 'px',
                                    marginBottom: TIMELINE_ROW.paddingBottom + 'px',
                                }}
                            >
                                <TimelineRow
                                    object={object}
                                    animationId={null}
                                    isMainRow={true}
                                    timelineRowWidth={timelineRowWidth}
                                    isHidingObject={
                                        isHidingObject ||
                                        (object.isHidden ? object.isHidden : false)
                                    }
                                />
                                {/* recursive for TimelineRows */}
                                {object.childIds.length > 0 && (
                                    <TimelineRows
                                        objects={selectObjectsByIds(stateObjects, object.childIds)}
                                        timelineRowWidth={timelineRowWidth}
                                        isHidingObject={
                                            isHidingObject ||
                                            (object.isHidden ? object.isHidden : false)
                                        }
                                    />
                                )}
                            </Box>
                        )
                })}
            </Grid>
        )
}

export default TimelineRows

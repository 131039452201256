import { Box, useTheme } from '@mui/material'
import { Property } from 'csstype'
import React, { ReactNode } from 'react'
import Draggable from 'react-draggable'
import { renderStyles } from '../../../../data/styles/styles'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import { useDraggable } from '../../../../hooks/editor/useDraggable'
import useWrapSelectedObject from '../../../../hooks/editor/useWrapSelectedObject'
import useWrapSelectedObjects from '../../../../hooks/editor/useWrapSelectedObjects'
import useDynamicRefs from '../../../../hooks/useDynamicRefs'
import { useAppSelector } from '../../../../hooks/useRedux'
import { CanvasCursor } from '../../../../store/slices/canvasCursor.slice'
import { selectObjectById } from '../../../../store/slices/objects.slice'
import { AppStateT } from '../../../../store/store'
import CanvasItem from '../CanvasItem'
import DraggableItem, { DRAGGABLE_ITEMS } from '../DraggableItem'

interface Props {
    object: AnySceneObjectT
    isHiding: boolean
    canvasScaleCoef: number
    borderCoef: number
    handleSelectObject: (
        event: React.MouseEvent,
        objectId: string,
        animationIds: string[],
        ctrlCmdKey: boolean
    ) => void
    children?: ReactNode
    visibility?: Property.Visibility | undefined
    className: string
}

const AbstractContainer = ({
    object,
    isHiding,
    canvasScaleCoef,
    borderCoef,
    handleSelectObject,
    children,
    visibility,
    className,
}: Props) => {
    const theme = useTheme()
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const state: AppStateT = useAppSelector((state) => state)
    const canvasCursor = useAppSelector((state) => state.canvasCursor.value)

    const objectStyles = renderStyles(object, 'inline')

    const [, setRef] = useDynamicRefs<HTMLDivElement>()

    const {
        handleOnStartDrag,
        handleDragging,
        handleDragEnd,
        dragging,
        draggableAxis,
        defaultSingleWrapperState,
        draggableScale,
        objectPositioning,
        setCursorWhenDragging,
    } = useDraggable(object, canvasScaleCoef, undefined)
    const draggableBoxRef = React.useRef<HTMLDivElement>(null)
    const { wrapperExist } = useWrapSelectedObjects()

    //hide objects when object is in selectedObjectIds and in wrapper
    const getVisibility = (objectId: string) => {
        if (selectedObjectIds.length > 1 && selectedObjectIds.find((id) => id === objectId)) {
            return true
        } else {
            return false
        }
    }
    const { wrapperObjectsStyles } = useWrapSelectedObject()

    return (
        <Box
            style={{ cursor: 'pointer', zIndex: object.index }}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Draggable
                position={{
                    x: 0,
                    y: 0,
                }}
                axis={draggableAxis}
                scale={draggableScale}
                onStop={handleDragEnd}
                onDrag={handleDragging}
                onStart={handleOnStartDrag}
                disabled={
                    wrapperExist ||
                    (selectedObjectIds.length === 1 &&
                        !selectedObjectIds.find((id) => id === object.id)) ||
                    canvasCursor === CanvasCursor.GrabCursorBySpace
                }
            >
                <Box>
                    <div
                        className={`${className} ` + object.id}
                        id={object.id}
                        ref={setRef(object.id)}
                        onClick={(e) =>
                            handleSelectObject(
                                e,
                                object.id,
                                Object.keys(object.animations),
                                e.metaKey || e.ctrlKey
                            )
                        }
                        style={{
                            ...objectStyles,
                            visibility: visibility,
                            outline: selectedObjectIds.find((id) => id === object.id)
                                ? `${borderCoef}px dotted ${theme.palette.secondary.light}`
                                : 'none',
                            cursor: setCursorWhenDragging,
                        }}
                    >
                        {children}
                        {object.childIds.map((childId: string, index: number) => (
                            <CanvasItem
                                key={index}
                                object={selectObjectById(selectUndoable(state).objects, childId)}
                                canvasScaleCoef={canvasScaleCoef}
                                isHiding={
                                    (object.isHidden ? object.isHidden : false) ||
                                    isHiding ||
                                    getVisibility(childId)
                                }
                                borderCoef={borderCoef}
                            />
                        ))}
                    </div>

                    {/* single object wrapper for every child object in selected object */}
                    {object.id === wrapperObjectsStyles.objectId &&
                        (defaultSingleWrapperState || dragging) && (
                            <Box
                                sx={{
                                    transformOrigin: 'top left',
                                    top: wrapperObjectsStyles.top,
                                    left: wrapperObjectsStyles.left,
                                    height: wrapperObjectsStyles.height,
                                    width: wrapperObjectsStyles.width,
                                    position: 'absolute',
                                    zIndex: -10,
                                    cursor: defaultSingleWrapperState ? 'pointer' : 'move',
                                    backgroundColor: defaultSingleWrapperState
                                        ? 'rgba(0,0,0,0.1)'
                                        : 'rgba(0,0,0,0.2)',
                                    outline: !wrapperExist
                                        ? `${borderCoef}px dotted ${theme.palette.warning.light}`
                                        : `${borderCoef}px dotted ${theme.palette.warning.main}`,
                                }}
                            ></Box>
                        )}
                </Box>
            </Draggable>
            {/* can not resizing when multi objects is selected*/}
            {!wrapperExist &&
                DRAGGABLE_ITEMS.map((x) => (
                    <DraggableItem
                        key={x.direction}
                        ref={draggableBoxRef}
                        borderCoef={borderCoef}
                        object={object}
                        position={objectPositioning}
                        canvasScaleCoef={canvasScaleCoef}
                        direction={x.direction}
                        axis={x.axis}
                    />
                ))}
        </Box>
    )
}

export default AbstractContainer
